import React from 'react';
import { FaInstagram, FaLinkedin, FaWhatsapp, FaDiscord, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div className="footer">
            <section className="contact">
                <div className="contact-info">
                    <div className='col1'>
                        <div className="contact-item">
                            <img src="/logo.png" alt="Logo" />
                            <p style={{lineHeight:'2.0', fontSize:'15px', marginTop:'30px'}}>
                                Reinventamos a forma como software houses, automações comerciais e comerciantes
                                operam e crescem seus negócios com tecnologia.
                            </p>
                            <div className="social-media mt-4">
                                <ul>
                                    <li><FaInstagram size={30} color='#000000' className='icon98 cursor-pointer' onClick={() => window.open('https://www.instagram.com/embed.it?igsh=d250Y240NmhmazE0', '_blank')}/></li>
                                    <li><FaLinkedin size={30} color='#000000' className='icon98 cursor-pointer' onClick={() => window.open('https://www.linkedin.com/company/embedbr/posts/?feedView=all', '_blank')} /></li>
                                    {/*<li><FaWhatsapp size={30} color='#000000' className='icon98 cursor-pointer' onClick={() => window.open('https://wa.me/5511966171536', '_blank')}/></li>
                                    <li><FaDiscord size={30} color='#000000' className='icon98 cursor-pointer' onClick={() => window.open('https://wa.me/5511966171536', '_blank')}/></li>*/}
                                    <li><FaGithub size={30} color='#000000' className='icon98 cursor-pointer' onClick={() => window.open('https://github.com/embed-labs', '_blank')}/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col1' style={{fontSize:'15px'}}>
                        <div className="contact-item" style={{fontSize:'15px'}}>
                            <h3 style={{fontSize:'16px'}}>Acesse as páginas</h3>
                            <ul className="" >
                                <li><Link to={'/'}>Home</Link></li> 
                                <li><Link to={'/about'}>A Embed</Link></li>
                                <li><Link to={'/comunidade'}>Comunidade</Link></li>
                            </ul>
                        </div>
                        <div className="contact-item">
                            <h3 style={{fontSize:'16px'}}>Soluções</h3>
                            <ul className="">
                                <li><Link to={'/tef'}>Embed.TEF</Link></li>
                                <li><Link to={'/pos'}>Embed.POS-Connect</Link></li>
                                <li><Link to={'/xml'}>Embed.XML</Link></li>
                                <li><Link to={'/xml'}>Embed.PIX</Link></li>
                                <li><Link to={'/xml'}>Embed.IAaS</Link></li>
                                <li><Link to={'/xml'}>Embed.AR</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='col1' >
                        <div className="contact-item">
                            <h3 style={{fontSize:'16px'}}>Atendimento</h3>
                            <p style={{fontSize:'15px'}}>De segunda a sexta-feira, das 08h00 às 18h00, exceto feriados nacionais e regionais.</p>
                        </div>

                        <div className="contact-item">
                            <h3 style={{fontSize:'16px'}}>Email</h3>
                            <p style={{fontSize:'15px'}}>contato@embed.it</p>
                        </div>
                        <div className="contact-item space-y-2">
                            <h3 style={{fontSize:'16px'}}>Fone</h3>
                            <p style={{fontSize:'15px'}} >Suporte: <span className='hover:text-[#80FF80] cursor-pointer' onClick={() => window.open('https://wa.me/5511966171536', '_blank')}>+55 11 96617-1536</span></p>
                            <p style={{fontSize:'15px'}} >Comercial: <span className='hover:text-[#80FF80] cursor-pointer' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>+55 41 99759-7962</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <div className='copyright px-4 text-[13px]'>
                <p>&copy; 2024 www.embed.it | Todos os direitos reservados.</p>
                <p className='hidden md:block'>ATMA</p>
            </div>
        </div>
    );
};

export default Footer;
