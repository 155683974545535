import React, { useState }  from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';


const Home = () => {

    {/*useEffect(() => {
        const carousel = document.querySelector(".testimonials-carousel");
        const prevBtn = document.querySelector(".prev-btn");
        const nextBtn = document.querySelector(".next-btn");

        let scrollAmount = 0;
        const scrollStep = carousel.offsetWidth / 2;

        prevBtn.addEventListener("click", () => {
            carousel.scrollBy({
                left: -scrollStep,
                behavior: "smooth",
            });
            scrollAmount = Math.max(0, scrollAmount - scrollStep);
        });

        nextBtn.addEventListener("click", () => {
            carousel.scrollBy({
                left: scrollStep,
                behavior: "smooth",
            });
            scrollAmount = Math.min(
                carousel.scrollWidth - carousel.clientWidth,
                scrollAmount + scrollStep
            );
        });

        return () => {
            prevBtn.removeEventListener("click", () => { });
            nextBtn.removeEventListener("click", () => { });
        };
    }, []);*/}

    const [activeIndex, setActiveIndex] = useState(0);

    const features = [
        {
            title: 'Hub de Pagamento (TEF)',
            description: 'Oferece integração com sistemas TEF para pagamentos em pontos de venda físicos.',
            icon: '🌐',
            link: 'tef'
        },
        {
            title: 'Smart POS Android Hub',
            description: 'Dispositivo Android multifuncional para pagamentos e gestão no ponto de venda.',
            icon: '💳',
            link: 'pos'
        },
        {
            title: 'Armazenamento de NFs',
            description: 'Gerencie e armazene suas notas fiscais de forma segura e centralizada.',
            icon: '👤',
            link: 'xml'
        },
        {
            title: 'Integração Pix',
            description: 'Facilita pagamentos instantâneos com integração completa ao Pix.',
            icon: '📊',
            link: ''
        },
        {
            title: 'Antecipação de Recebimento',
            description: 'Receba seus valores antecipadamente com rapidez e segurança.',
            icon: '📊',
            link: ''
        },
        {
            title: 'I.A as a Service',
            description: 'Soluções de inteligência artificial personalizadas para o seu negócio.',
            icon: '📊',
            link: ''
        },
    ];

    return (
        <div className="home">
            {/* Seção Hero com Vídeo de Fundo */}
            <section className="hero">
                <video autoPlay loop muted playsInline className="background-video">
                    <source src="/home/background.mp4" type="video/mp4" />
                    Seu navegador não suporta vídeos.
                </video>
                <div className="hero-text">
                    <h1><span className="highlight">Reinventando</span> a forma como </h1>
                    <h1><span className="highlight">software houses</span> e <span className="highlight">automações comerciais</span></h1>
                    <h1>operam e crescem seus negócios, prosperam</h1>
                    <h1>e expandem seus horizontes</h1>
                </div>
            </section>
            {
                
            }

            {/* Seção Sobre a Embed */}
            <section className="about">
                <div className="about-content">

                    <div className="about-content-text mt-6 text-[25px] md:text-[30px]">
                        <h2>Soluções agnósticas para <span style={{fontWeight:'bold', marginLeft:5}}>comprar, vender, pagar </span> <img style={{marginLeft:10}} src="home/logo2.png" alt="Sobre a Embed" className="about-image" /><span style={{fontWeight:'bold', marginLeft:5}}>receber</span> </h2>
                    </div>
                    <p  className= 'text-justify' style={{fontWeight:'inherit', marginBottom:'30px'}}>
                        Uma comunidade feita para software houses! Somos uma plataforma dedicada a capacitar software houses na <span style={{fontWeight:'bolder'}}>distribuição de soluções e serviços financeiros para o varejo</span>. Faça parte de uma rede que impulsiona <span style={{fontWeight:'bolder'}}>inovação, crescimento e sucesso para o seu negócio</span>.    
                    </p>
                </div>
            </section>

            {/* Nova Seção - Conheça Nossas Soluções */}
            <section className="about-solutions">
                <div className="solutions-content">
                    <div className="solutions-text">
                        <p className="solutions-heading">Nossas Soluções</p>
                        <p style={{fontWeight:'inherit', fontSize:'28px', margin: '0'}}>Negócios operam e crescem</p>
                        <p style={{fontSize:'28px', margin: '0', marginBottom: '40px'}}><span style={{fontWeight:'bolder'}}>com uma única integração</span></p>
                        <div className='text-justify md:text-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p style={{maxWidth:'1100px', lineHeight: '1.5', fontSize:'18px', marginBottom:'50px'}}>
                                Com APIs fáceis de integrar e dados conectados, potencializamos o crescimento do seu negócio e dos negócios dos seus clientes 
                                através do nosso ecossistema. <span style={{fontWeight:'bolder'}}>Para qualquer tipo de pagamento e fluxo de dados, em qualquer canal, em qualquer dispositivo, 
                                conte com a Embed para transformar o varejo</span>.
                            </p>
                        </div>
                        <div className='listImage flex flex-col md:flex-row items-start gap-6'>
                            <ul className="solutions-list flex-1 pl-6">
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Antecipação de recebíveis</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">POS Multi-adquirente</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Motores de pagamento (TEFs)</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Pix e Wallets</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Armazenamento de NFs</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Conexão com Smart POS Android</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Pagamento de contas no PDV</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Conta digital</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Loyalty</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">IA personalizada</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Empréstimos para o Varejo</li>
                            </ul>
                            <div className="solutions-image flex-1 flex justify-center md:justify-end">
                                <img src="home/person1.png" alt="Imagem descritiva" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about-dll py-[20px] px-4 md:px-[80px] text-[18px] md:text-[24px]" >
                <div className='text1'>
                    <p><span>Code once_</span></p>
                    <p>Uma vez Embed,</p>
                    <p>Embedado está.</p>
                </div>
                <div className='text2'>
                    <p>/um único idioma</p>
                    <p>/uma única integração</p>
                    <p>/um único protocolo</p>
                </div>
                <div className='text3'>
                    <p>/sem DLL Hell</p>
                    <p>/sem API Hell</p>
                    <p className='text-white'>_muito mais fácil.</p>
                </div>
            </section>

            {/* Seção Soluções */}
            <section className="solutions">
                <h2>Soluções em destaque</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-2 items-center'>
                    {features.map((feature, index) => (
                        <div className='bg-white hover:bg-[#F5F5F5] p-8 shadow-md text-start cursor-pointer h-[200px]' key={index}>
                            <Link to={`/${feature.link}`} className='block'>
                                <div className="text-3xl mb-[10px]">{feature.icon}</div>
                                <h3 className="text-md font-semibold mb-[5px]">{feature.title}</h3>
                                <p className="text-gray-600 text-sm">{feature.description}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>


            {/* Seção Empresas Parceiras */}
            <section className="partners">
                
                <p className='mb-4'>EMPRESAS PARCEIRAS</p>
                <h4>Quem evolui o ecossistema com a Embed</h4>
                <div className="partners-list justify-center">
                    <img src="home/microsoft.png" alt="Microsoft" />
                    <img src="home/visa.png" alt="Visa" />
                    <img src="home/google.png" alt="Google" />
                    <img src="home/get-net.png" alt="GetNet" />
                    <img src="home/mastercard.svg" alt="MasterCard" />
                </div>
            </section>

            {/*
             Seção Depoimentos 
            <section className="testimonials px-4">
                <p className="title">DEPOIMENTOS</p>
                <h4>Com a palavra, os membros da nossa comunidade</h4>
                <div className="testimonials-carousel">
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"A Embed revolucionou nossa forma de operar no mercado!"</p>
                            <p style={{color:'white', margin:0}}>Heloisa Lisboa</p>
                            <p style={{margin:0, fontWeight:'bold'}}>CEO da SWH Curitibana</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"Uma experiência incrível com soluções eficazes!"</p>
                            <p style={{color:'white', margin:0}}>Fellipe Costa</p>
                            <p style={{margin:0, fontWeight:'bold'}}>Diretor da Atma Sistemas</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"O suporte da Embed é impecável, sempre atentos às nossas necessidades! Recomendo"</p>
                            <p style={{color:'white', margin:0}}>Carla Dias</p>
                            <p style={{margin:0, fontWeight:'bold'}}>CTO da TecVendas</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"Facilitou muito nosso processo de integração com o PDV, altamente recomendada!"</p>
                            <p style={{color:'white', margin:0}}>Marcos Ferreira</p>
                            <p style={{margin:0, fontWeight:'bold'}}>Diretor da Solux Sistemas</p>
                        </blockquote>
                    </div>
                </div>
                <div className="carousel-controls">
                    <button className="prev-btn">‹</button>
                    <button className="next-btn">›</button>
                </div>
            </section>
            */}
            <section className="options-section">
                <div className="option-card">
                    <img src="/community-icon.png" alt="Ícone da Comunidade" />
                    <p className='title'><span>Faça parte da
                        comunidade que </span>está
                        mudando o varejo!</p>
                    <p>Preencha o formulário clicando abaixo
                        e entraremos em contato com você
                        em breve para iniciar os trabalhos.</p>
                    <button className='rounded-md' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSf5VYQzkPWJTbgqIA7Cg787U_5kW4lM5yg5Jfur5f1FLT9n0g/viewform', '_blank')}>Quero ser Embed!</button>
                </div>
                <div className="option-card">
                    <img src="/contact-icon.png" alt="Ícone de Contato" />
                    <p className='title'><span>Entre em contato </span>
                        com a Embed pelo
                        nosso WhatsApp:</p>
                    <p>Nosso time está à disposição no
                        WhatsApp para tirar dúvidas e apoiar você
                        no preenchimento dos primeiros dados.</p>
                    <button className='rounded-md' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>Fale conosco</button>
                </div>

            </section>
        </div>
    );
};

export default Home;
